import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from 'gsap/SplitText';
import CustomEase from 'gsap/CustomEase';
import Rellax from 'rellax/rellax.min';
import Lenis from '@studio-freight/lenis';
class Main {
    constructor() {
        this.initialize();
    }

    initialize() {
        //this class is applying css transition duration of 0 to prevent animating from resetting when refreshing the page.
        document.querySelector('body').classList.remove('preload');

        this.breakpoints = [
            window.SETTINGS.BREAKPOINTS.SM_MIN,
            window.SETTINGS.BREAKPOINTS.MD_MIN,
            window.SETTINGS.BREAKPOINTS.LG_MIN
        ];

        // Init smoothscroll
        const lenis = new Lenis({
            wheelMultiplier: 0.9,
        });

        lenis.on('scroll', ScrollTrigger.update);
        gsap.ticker.add((time) => {
            lenis.raf(time * 1000);
        });

        window.lenis = lenis;

        this.addEvents();
        this.initParallax();
        this.initFadeIns();

        window.dispatchEvent(new Event('resize'));

        CustomEase.create("qcq-ease", "M0,0 C0.19,1 0.22,1 1,1 ")
        CustomEase.create("qcq-ease-reverse", "M0,0 C0.81,0 0.78,0 1,1 ")
        ScrollTrigger.config({ignoreMobileResize: true});
    }

    addEvents() {
        window.addEventListener('resize', this.resize.bind(this));
        window.addEventListener('pageTransition', this.barbaPageLoaded.bind(this));
        window.addEventListener('dataLayer.push', this.dataLayerPush.bind(this));
        document.addEventListener('afterBlitzInject', this.initViews.bind(this));

        document.dispatchEvent(new CustomEvent("blitzUpdate"));
    }

    removeEvents() {
        window.removeEventListener('resize', this.resize.bind(this));
        window.removeEventListener('pageTransition', this.barbaPageLoaded.bind(this));
        window.removeEventListener('dataLayer.push', this.dataLayerPush.bind(this));
        document.removeEventListener('afterBlitzInject', this.initViews.bind(this));


        if(this.rellax){
            this.rellax.destroy();
        }
    }

    barbaPageLoaded(){
        document.dispatchEvent(new CustomEvent("blitzUpdate"));

        this.initParallax();
        this.initFadeIns();

        if(!document.querySelector('html').classList.contains('checkout')){
            if(localStorage.getItem("TTG-Cart")){
                localStorage.removeItem("TTG-Cart");
            }
            if(localStorage.getItem("TTG-Receipt")){
                localStorage.removeItem("TTG-Receipt");
            }
        }
    }

    initParallax(){
        if(this.rellax){
            this.rellax.destroy();
        }

        if(document.querySelectorAll('.rellax').length != 0){
            this.rellax = new Rellax('.rellax',{
                horizontal: true
            });
        }
    }

    initFadeIns(){
        const fadeIns = gsap.utils.toArray('.fade-in');
        if (fadeIns.length != 0) {
            fadeIns.forEach((fadeIn) => {
                gsap.fromTo(
                    fadeIn,
                    {
                        opacity: 0,
                        y: 160,
                    },
                    {
                        opacity: 1,
                        y: 0,
                        scrollTrigger: {
                            trigger: fadeIn,
                            start: 'top 115%',
                        },
                        duration: 1,
                        ease: 'qcq-ease',
                        onComplete: function () {
                            gsap.set(this.targets(), { clearProps: "all" });
                        }
                    },
                );
            });
        }
    }

    initViews() {
        Me.manage.initViews();
    }

    resize(e) {
        let breakpoint;
        let viewPortSize = this.GET_OUTER_WIDTH(document.querySelector('html'));

        if(viewPortSize <= window.SETTINGS.BREAKPOINTS.XS_MAX){
            window.isMobile = true;
            window.isTablet = false;
        } else if(viewPortSize <= window.SETTINGS.BREAKPOINTS.SM_MAX) {
            window.isTablet = true;
            window.isMobile = false;
        } else{
            window.isTablet = false;
            window.isMobile = false;
        }

        if (viewPortSize < this.breakpoints[0]) {
            breakpoint = String(this.breakpoints[0] - 1) + "-";
        }

        for (let i in this.breakpoints) {
            if (this.breakpoints[Number(i) + 1] != undefined && viewPortSize >= this.breakpoints[i] && viewPortSize < this.breakpoints[Number(i) + 1]) {
                breakpoint = String(this.breakpoints[i]) + "-" + String(this.breakpoints[Number(i) + 1] - 1);
            }
            if (viewPortSize >= this.breakpoints[this.breakpoints.length - 1]) {
                breakpoint = String(this.breakpoints[i]) + "+";
            }
        }

        if (window.SETTINGS.BREAKPOINT != breakpoint) {
            window.SETTINGS.BREAKPOINT = breakpoint;

            let eventDispatch = new CustomEvent("breakpointChanged", {bubbles: true, detail: {
                    breakpoint: breakpoint
                }
            });

            //dispatch breakpointChanged
            window.dispatchEvent(eventDispatch);
        }

        // window.dispatchEvent(new Event("window.resize"));
    }

    GET_OUTER_WIDTH(element) {
        let width = element.offsetWidth;
        let style = getComputedStyle(element);

        width += parseInt(style.marginLeft) + parseInt(style.marginRight);
        return width;
    }

    dataLayerPush(params) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push(params.detail);

        this.resetDataLayer();
    }

    resetDataLayer(){
        dataLayer.push(function() {
            this.reset();
        })
    }
}

gsap.registerPlugin(ScrollTrigger, SplitText, CustomEase);
document.addEventListener('DOMContentLoaded', function() {
    new Main();
});
